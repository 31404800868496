<template lang="pug">
div#article-index
  div.banner(ref="banner"): img(:src="articles.category?articles.category.cate_top_img:require('@/assets/tmp/2.png')")
  div.article-index-list-box(ref="list" :style="`height: ${listBoxHeight}px`" @scroll="_scrollMore")
    listBox(:objs="articles.list")/
</template>
<script>
import listBox from '@/components/article/list'
export default {
  components: {listBox},
  props: {
    cate_id: Number,
    uri_func: String,
  },
  data(){return {
    articles: [],
    page: 1,
    listBoxHeight: 0,
    liHeight: 0, // 一li的高度
    scrollIO: true, // 滚动进行数据请求，避免一下来就请求
  }},
  methods: {
    getList(){
      return new Promise((resolve)=>{
        this.$req[this.uri_func]({cate_id: this.cate_id, page: this.page}, (res)=>{
          resolve(res)
        })
      })
    },
    _scrollMore(e){
      if(this.scrollIO){
        let {scrollTop} = e.target
        let full_height = this.articles.list.length * this.liHeight // 应该的总高度
        if(full_height - this.listBoxHeight - scrollTop <= 3){
          this.scrollIO = false
          setTimeout(()=>{
            this.page++
            this.getList().then(res=>{
              this.articles.list = this.articles.list.concat(res.list)
              this.scrollIO = res.list.length > 0
            })
          }, 700)
        }
      }
    }
  },
  mounted(){
    this.getList().then((res)=>{
      this.articles = res
    }).then(()=>{
      let banner_h = this.$refs.banner.offsetHeight
      let {topbar, win} = this.$store.state.heights
      this.listBoxHeight = win - topbar - banner_h - 30 // 30是margin
      this.liHeight = this.$refs.list.querySelector('li').offsetHeight
    })
  }
}
</script>
<style scoped lang="less">
@import "~@/assets/less/spacing.less";
@import "~@/assets/less/func.less";
#article-index{
  .banner img{width: 100%;}
  .article-index-list-box{overflow-y: scroll;}
}
</style>