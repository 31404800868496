<template lang="pug">
div.list-box.margin._15_0
  ul
    li.flex.mod(v-for="(o,i) in objs" :key="i")
      img.ico(:src="require(`@/assets/img/${o.cate_id}.png`)")
      div.grow
        h3.e: router-link(:to="link(o.cate_id, o.atid)") {{o.at_title}}
        div.small.flex.txt.grey.margin._10_0
          div.grow 发布时间：{{o.atcreate_time}}
          div.num.flex
            img(src="@/assets/ico/redheart.png" @click="_zan(o.atid, i)")
            span {{o.good_num}}
            img(src="@/assets/ico/toshare.png")
            span {{o.share_num}}
        router-link(:to="link(o.cate_id, o.atid)").content.flex
          div.img-box: img(:src="o.list_img")
          p.e3 {{o.at_con}}
</template>
<script>
export default {
  props: {
    objs: {
      type: Array,
      default: []
    }
  },
  methods: {
    link(cate_id, atid){
      let path = `/${{c1: 'suishenting', c2: 'lianliankan', c999: 'wolaipei'}['c'+cate_id]}/${atid}`
      cate_id == 999 && (path = '/wocanyu'+path)
      return path
    },
    _zan(atid, i){
      this.$req.articleZan({atid}, (res)=>{
        res.setInc/1 && this.objs[i].good_num++
        this.$store.commit('tipShow', res.tip)
      })
    },
  }
}
</script>
<style scoped lang="less">
@import "~@/assets/less/spacing.less";
@import "~@/assets/less/func.less";
.flex{ align-items: flex-start;}
.list-box{
  li:not(:last-child){
    .__border(bottom, #ddd)
  }
  h3{
    width: 18em;
  }
  .ico{.__wh(50, 50, 10);}
  .num{
    img{.__wh(15, 15, 5); }
    span{.__rem(5, margin-right); .block();}
  }
  .content{.__rem(12);}
  .img-box{
    img{.__wh(80, 50, 10);}
  }
}
</style>